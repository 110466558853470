import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from './Footer';
import { useUserContext } from '../contexts/UserContext';
import { getAllPerson, getUser } from '../Redux/actions';
import { useDispatch } from "react-redux";

export default function RentHome() {
    const [homes, setHomes] = useState([]);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const keys = ["nameHome", "categorie"];
    const { user } = useUserContext(); // Access context

    useEffect(() => {
        if (!user) {
            navigate('/'); // Redirect if not logged in
            return;
        }
        fetch(`https://mayedo.onrender.com/homes?user_id=${user.id}`)
            .then((res) => res.json())
            .then(setHomes) // Directly set homes from response
            .catch((error) => console.error('Error fetching homes:', error));
    }, [user, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await fetch(`https://mayedo.onrender.com/users/${user?.id}`);
                const userData = await userResponse.json();
                dispatch(getUser(userData));
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        fetchData();
    }, [user, dispatch]);

    // Simplified filtering logic
    const filteredHomes = homes.filter(home =>
        keys.some(key =>
            home[key]?.toLowerCase().includes(search.toLowerCase())
        )
    );

    return (
        <div>
            <Navbar />
            <div className="containers">
                <div className="dashboard">
                    <div className="left">
                        <Sidebar />
                    </div>
                    <div className="right">
                        <div className="firstly">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Nos locations</li>
                                </ol>
                            </nav>
                            <div className="firstly__header">
                                <h1><i className="fa-solid fa-house"></i> Mes propriétés </h1>
                                <div className="ml__add">
                                    <div className="form-floating col-8">
                                        <input type="text" className="form-control" placeholder="Rechercher" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <label htmlFor="floatingInput">Rechercher par Nom ou Categorie ...</label>
                                    </div>
                                    <button className="add__home" onClick={() => navigate(`/add__home/${user?.id}`)}> <i className="fa-solid fa-plus"></i> Ajouter une propriété</button>
                                </div>
                            </div>
                            <div className="container__renthome">
                                {filteredHomes.map((home) => (
                                    <div key={home._id} className="renthome__cards">
                                        <div className="renthome__cards__images">
                                            <div className="renthome__cards__images__box">
                                                {home.imageUrl.map((url, index) => (
                                                     <Link to={`/detailHome/${home._id}`}><img key={index} src={`http://localhost:4000${url}`} alt={`Home ${index}`} className="home-image" /></Link>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="renthome__cards__description">
                                            <div className="cards__description__content">
                                            <h5>{home.nameHome}</h5>
                                            <p>{home.addressHome}</p>
                                            {/* <p>{home.superficie}</p> */}
                                            <p>{home.rent} F CFA</p>
                                            </div>

                                            <div className="cards__description__content">
                                            <p>{home.description}</p>
                                            <p>{home.guarantee}</p>
                                            <p>{home.categorie}</p>
                                            </div>
                                        </div>
                                        <div className="renthome__cards__details">
                                            <Link to={`/detailHome/${home._id}`}>
                                                <button className="btn__det">voir details <i className="fa-solid fa-arrow-right"></i></button>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}