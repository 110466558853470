import React from 'react';

const DeletePerson = ({  delPerson,setDelPerson, confirmDeletePerson }) => {
    const delDisplay = () => {
        setDelPerson(false);
    };

    return (
        <div className={delPerson ? "container__delete__person open__delete__person" : "container__delete__person"}>
            <div className='content__delete__modal'>
                <div>
                <p>Êtes-vous sûr de vouloir supprimer ?</p>
                <p>Notez que cette action est irréversible !!</p>
                <div>
                    <button className='btn__delete__rent' onClick={delDisplay}>Annuler</button>
                    <button className='btn__delete__rent' onClick={confirmDeletePerson}><i className="fa-solid fa-trash"></i> Supprimer</button>
                </div>
                </div>
            </div>
        </div>
    );
}

export default DeletePerson;
