import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { getHome, getPerson, getRent } from "../Redux/actions";
import Footer from "./Footer";


export default function DetailHome() {
    const id = useParams().id;
    const rentHome = useSelector(state => state.peopleReducer.rentHome);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(`https://mayedo.onrender.com/homes/${id}`)
            .then((res) => res.json())
            .then((rentHome) => { dispatch(getHome(rentHome)) })
            .catch(e => { console.log(e) })
    }, [dispatch, id]);

    return (
        <div>
            <Navbar />
            <div className="containers">
                <div className="dashboard">
                    <div className="left">
                        <Sidebar />
                    </div>
                    <div className="right">
                        <div className="firstly">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    {/* <li className="breadcrumb-item"><Link to='/persons'>Locataires</Link></li> */}
                                    {/* <li className="breadcrumb-item active" aria-current="page">Details du profil</li> */}
                                </ol>
                            </nav>
                            <div className='container__detail'>
                                <div className="main__part__detail__people">
                                    <div className='btn__close__block'>
                                        <Link to='/rentHome'><button className='btn__back'><i className="fa-solid fa-arrow-left"></i></button></Link>
                                    </div>
                                    <div className="title__detail">
                                        <h1>{rentHome.nameHome} {rentHome.categorie}</h1>
                                    </div>
                                    <div className="main__part__home">
                                        <div key={id} className="main__part__home__image">
                                            {rentHome.imageUrl && rentHome.imageUrl.map((url, index) => (
                                                <img key={index} src={`http://localhost:4000${url}`} alt={`Home ${index}`} className="home-image" />
                                            ))}
                                        </div>
                                    </div>
                                    <div key={id} className="part__detail__home">
                                        <div className="detail--home">
                                            <p>Descriptions :</p>
                                            <h5>{rentHome.nameHome}</h5>
                                            <p>{rentHome.addressHome}</p>
                                            <p>{rentHome.rent} F CFA</p>
                                            <p>{rentHome.description}</p>
                                            <p>{rentHome.guarantee}</p>
                                            <p>{rentHome.categorie}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}