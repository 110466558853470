import { useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Footer from "./Footer";
import { useUserContext } from '../contexts/UserContext';
import { getAllPerson, getUser } from '../Redux/actions';
import Navbar from "./Navbar";




export default function Profil(){
    const dispatch =useDispatch();
    const navigate = useNavigate();
    const { user, clearUser } = useUserContext(); // Access context

    console.log('zer',user)

    useEffect(() => {
        if (!user) {
          navigate('/'); // Redirect if not logged in
          return;
        }
    
        const fetchData = async () => {
          try {
            const userResponse = await fetch(`https://mayedo.onrender.com/users/${user?.id}`);
            const userData = await userResponse.json();
            dispatch(getUser(userData));
          } catch (error) {
            console.error('Failed to fetch data:', error);
          }
        };
    
        fetchData();
      }, [user, dispatch, navigate]);

    return (
        <div>
              <Navbar />
        <div className="containers">
        <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                      
                       <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Paramètre du compte</li>
                                </ol>
                            </nav>
                               <div className="profil__content__box">
                               <h1 className="firstly__title"><i class="fa-solid fa-user"></i>  Mon profil</h1>
                            <button className="btn__update__box" onClick={() => navigate(`/updateProfil/${user?.id}`)}> <i class="fa-solid fa-pen-to-square"></i> Mettre à jour</button>
                               </div>

                                <div className="profil__container">
                                    <div className="profil__first__part">
                                        <div className="profil__first__part__content">
                                        <img src='27.PNG' alt="" />
                                        </div>
                                    </div>
                                    <div className="profil__second__part">
                                        <p>Paramètre du compte</p>
                                    {/* <form class="row g-3 needs-validation" novalidate>
                                        <div class="col-md-5">
                                            <label for="validationCustom01" class="form-label">Nom</label>
                                            <input type="text" class="form-control" id="validationCustom01" required />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="validationCustom02" class="form-label">Prenoms</label>
                                            <input type="text" class="form-control" id="validationCustom02"  required />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="validationCustom02" class="form-label">Email</label>
                                            <input type="email" class="form-control" id="validationCustom02"  required />
                                        </div>

                                        <div class="col-md-5">
                                            <label for="validationCustom03" class="form-label">Addresse</label>
                                            <input type="text" class="form-control" id="validationCustom03" required/>
                                        </div>
                                        <div class="col-md-5">
                                            <label for="validationCustom03" class="form-label">Contacts</label>
                                            <input type="text" class="form-control" id="validationCustom03" required/>
                                        </div>
                                        <div class="col-12">
                                        <button class="btn__cancel" type="submit">Annuler</button>
                                            <button class="btn__form" type="submit">Enregistrer</button>
                                        </div>
                                        </form> */}
                                         <p className="user__info"> Nom : {user.name}</p>
                                         <p className="user__info"> Prénom : {user.prenom}</p>
                                         <p className="user__info"> Username : {user.username}</p>
                                        <p className="user__info">Email : {user.email}</p>
                                        <p className="user__info">Address : {user.address}</p>
                                        <p className="user__info"> Numero : {user.numero}</p>
                                        {/* <p>Email: {user.email}</p> */}
                                    </div>
                                </div>
                            <div>
                            </div>
                           </div>
                       </div> 
                   </div>
    </div>
    <Footer/>
   </div>
    )
}