import { Link, useNavigate, useParams } from "react-router-dom"; // Import useNavigate for redirection
import FilterOrder from "../Components/FilterOrder";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getAllHomes } from "../Redux/actions";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { useUserContext } from '../contexts/UserContext';
import { getAllPerson, getUser } from '../Redux/actions';

export default function AddHome() {
    const rentHomes = useSelector((state) => state.peopleReducer.rentHomes);
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize navigate for redirection
    const [nameHome, setNameHome] = useState('');
    const [addressHome, setAddress] = useState('');
    const [superficie, setSuperficie] = useState('');
    const [rent, setRental] = useState('');
    const [description, setDescription] = useState('');
    const [guarantee, setGuarantee] = useState('');
    const [categorie, setCategorie] = useState('');
    const [uploads, setUpload] = useState(null); // Change to hold file object
    const { user, clearUser } = useUserContext(); // Access context
    const { id } = useParams(); // Get id from URL parameters
    
    // Set user_id to id from URL
    const user_id = id; // Assign the id from URL to user_id

    useEffect(() => {
        if (!user) {
          navigate('/'); // Redirect if not logged in
          return;
        }
    
        const fetchData = async () => {
          try {
            const personResponse = await fetch('https://mayedo.onrender.com/persons');
            const userResponse = await fetch(`https://mayedo.onrender.com/users/${user?.id}`);
    
            const personData = await personResponse.json();
            const userData = await userResponse.json();
    
            dispatch(getAllPerson(personData));
            dispatch(getUser(userData));
          } catch (error) {
            console.error('Failed to fetch data:', error);
          }
        };
    
        fetchData();
      }, [user, dispatch, navigate]);

    const handleFileChange = (e) => {
        setUpload(e.target.files); // Store multiple file objects
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('nameHome', nameHome);
        formData.append('addressHome', addressHome);
        formData.append('superficie', superficie);
        formData.append('rent', rent);
        formData.append('description', description);
        formData.append('guarantee', guarantee);
        formData.append('categorie', categorie);
        formData.append('user_id', user_id); // Use user_id from URL

        Array.from(uploads).forEach(file => {
            formData.append('uploads', file);
        });
    
        fetch('https://mayedo.onrender.com/add-home', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Home added:', data);
                toast.success('Home added successfully!'); // Show success toast
                navigate('/renthome'); // Redirect to RentHomePage
            })
            .catch((error) => {
                console.error('Error adding home:', error);
                toast.error('Error adding home. Please try again.'); // Show error toast
            });
    };

    console.log('addHome', user_id);

    return (
        <div>
            <ToastContainer /> {/* Add ToastContainer here */}
            <div className="containers">
                <div className="dashboard">
                    <div className="left">
                        <Sidebar />
                    </div>
                    <div className="right">
                        <div className="firstly">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Nos locations</li>
                                </ol>
                            </nav>
                            <div className="firstly__header">
                                <h1><i className="fa-solid fa-house"></i> Ajouter une propriété </h1>
                            </div>
                            <div className="container__addHome">
                                <div className="fo">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="Nom de la propriété" onChange={(e) => setNameHome(e.target.value)} value={nameHome || ""} />
                                            <label htmlFor="floatingInput">Nom de la propriété</label>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingPassword" placeholder="Adresse de la propriété" onChange={(e) => setAddress(e.target.value)} value={addressHome || ""} />
                                            <label htmlFor="floatingPassword">Adresse de la propriété</label>
                                        </div>
                                        
                                        <div>
                                            <label htmlFor="validationDefault02" className="form-label">Selectionner la catégorie</label>
                                            <select className="form-select form-select-sm mb-3" aria-label="Small select example" required onChange={(e) => setCategorie(e.target.value)} value={categorie}>
                                                <option selected value="">Sélectionner</option>
                                                <option value="Appartement">Appartement</option>
                                                <option value="Villa">Villa</option>
                                            </select>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingPassword" placeholder="Description de la propriété" onChange={(e) => setDescription(e.target.value)} value={description || ""} />
                                            <label htmlFor="floatingPassword">Description</label>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingPassword" placeholder="Garantie de la propriété" onChange={(e) => setGuarantee(e.target.value)} value={guarantee || ""} />
                                            <label htmlFor="floatingPassword">Garantie</label>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingPassword" placeholder="Superficie de la propriété" onChange={(e) => setSuperficie(e.target.value)} value={superficie || ""} />
                                            <label htmlFor="floatingPassword">Superficie</label>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="floatingPassword" placeholder="Loyer mensuel de la propriété" onChange={(e) => setRental(e.target.value)} value={rent || ""} />
                                            <label htmlFor="floatingPassword">Loyer mensuel</label>
                                        </div>

                                        <div className="input-group mb-3">
                                            <input type="file" className="form-control" id="inputGroupFile02" onChange={handleFileChange} name="uploads" multiple />
                                            <label className="input-group-text" htmlFor="inputGroupFile02">Upload</label>
                                        </div>
                                        <button type="submit" className="btn__add__home">Enregistrer</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}