import './App.css';
import {Routes,Route} from 'react-router-dom';
import 'animate.css';
import Accueil from './Pages/Accueil';
import AddPerson from './Pages/AddPerson';
import UpdatePerson from './Pages/UpdatePerson';
import Notif from './Pages/Notif';
import Profil from './Pages/Profil';
import Home from './Pages/Home';
import RentHome from './Pages/RentHome';
import Paiement from './Pages/Paiement';
import DetailPerson from './Pages/DetailPerson';
import DetailHome from './Pages/DetailHome';
import Receipt from './Pages/Receipt';
import Help from './Pages/Help';
import PaiementDetail from './Pages/PaiementDetail';
import Statistiques from './Pages/Statistiques';
import Inscription from './Pages/Inscription';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegisterMiddleware from './middlewares/RegisterMiddleware';
import AuthMiddleware from './middlewares/AuthMiddleware';
import { DataProvider } from './Pages/DataProvider';
import Navbar from './Pages/Navbar';
import NotFoundPage from './Pages/NotFoundPage';
import AddHome from './Pages/AddHome';
import { UserProvider } from './contexts/UserContext';
import Persons from './Pages/Persons';
import UpdateRent from './Pages/UpdateRent';
import UpdateProfil from './Pages/UpdateProfil';
import RedirectIfAuthenticated from './middlewares/RedirectIfAuthenticated';
import RequireAuth from './middlewares/RequireAuth';



export default function App() {
  return (
    <UserProvider>
      {/* <Navbar/> */}
    <Routes>
    <Route path='/' element={<RedirectIfAuthenticated><Home/></RedirectIfAuthenticated>}/>
    <Route path='/Accueil' element={<RequireAuth><Accueil/></RequireAuth>}/>
    <Route path='/persons' element={<RequireAuth><Persons/></RequireAuth>}/>
    <Route path='/notifications' element={<RequireAuth><Notif/></RequireAuth>}/>
    <Route path='/addPerson/:id' element={<RequireAuth><AddPerson/></RequireAuth>}/>
    <Route path='/mon-profil' element={<RequireAuth><Profil/></RequireAuth>}/>
    <Route path='/UpdatePerson/:id' element={<RequireAuth><UpdatePerson/></RequireAuth>}/>
    <Route path='/renthome' element={<RequireAuth><RentHome/></RequireAuth>}/>
    <Route path='/paiement/:id' element={<RequireAuth><Paiement/></RequireAuth>}/>
    <Route path='/detailPerson/:id' element={<RequireAuth><DetailPerson/></RequireAuth>}/>
    <Route path='/detailHome/:id' element={<RequireAuth><DetailHome/></RequireAuth>}/>
    <Route path='/receipt/:id' element={<RequireAuth><Receipt/></RequireAuth>}/>
    <Route path='/helps' element={<RequireAuth><Help/></RequireAuth>}/>
    <Route path='/paiementDetail/:id' element={<RequireAuth><PaiementDetail/></RequireAuth>}/>
    <Route path='/statistiques' element={<RequireAuth><Statistiques/></RequireAuth>}/>
    <Route path='/new__inscription' element={<RedirectIfAuthenticated><Inscription/></RedirectIfAuthenticated>}/>
    <Route path='/add__home/:id' element={<RequireAuth><AddHome/></RequireAuth>}/>
    <Route path='/updateRent/:id' element={<RequireAuth><UpdateRent/></RequireAuth>}/>
    <Route path='/updateProfil/:id' element={<RequireAuth><UpdateProfil/></RequireAuth>}/>
    <Route path="*" element={<RequireAuth><NotFoundPage /></RequireAuth>} />
  </Routes>
  <ToastContainer position="top-right" autoClose={3001} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false}  pauseOnFocusLoss draggable  pauseOnHover theme="light"  />
  </UserProvider>
  );
}

