import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { getPeople } from "../Redux/actions";
import Navbar from "./Navbar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { getPerson ,getAllHomes} from "../Redux/actions";
import Footer from "./Footer";
import { useUserContext } from '../contexts/UserContext';
import { getAllPerson, getUser } from '../Redux/actions';



export default function Update(){
    var id = useParams().id;
    const person = useSelector(state=>state.peopleReducer.person);
    const rentHomes = useSelector(state=>state.peopleReducer.rentHomes);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useUserContext(); 
    const [homes, setHomes] = useState([]);

    useEffect(() => {
        if (!user) {
            navigate('/'); // Redirect if not logged in
            return;
        }
        fetch(`https://mayedo.onrender.com/homes?user_id=${user.id}`)
            .then((res) => res.json())
            .then(setHomes) // Directly set homes from response
            .catch((error) => console.error('Error fetching homes:', error));
    }, [user, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await fetch(`https://mayedo.onrender.com/users/${user?.id}`);
                const userData = await userResponse.json();
                dispatch(getUser(userData));
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        fetchData();
    }, [user, dispatch]);

    // useEffect(() => {
    //     fetch(`https://mayedo.onrender.com/persons/${id}`)
    //     .then((res)=>res.json())
    //     .then((person)=>{dispatch(getPerson(person))})
    //     .catch(e => { console.log(e)})
    //     }, [person])
    
        // useEffect(() => {
        //     fetch('https://mayedo.onrender.com/homes')
        //     .then((res)=>res.json())
        //     .then((rentHomes)=>{dispatch(getAllHomes(rentHomes))})
        //     .catch(e => { console.log(e)})
        //     }, [])




    const [name,setName]=useState("");
    const [prenom,setPre]=useState("");
    const [birth,setDate]=useState("");
    const [lieu,setLieu]=useState("");
    const [nationality,setNationality]=useState("");
    const [situation,setSituation]=useState("");
    const [sexe,setSexe]=useState("");
    const [tel,setTel]=useState("");
    const [profession,setProfession]=useState("");
    const [address,setAddresse]=useState("");
    const [email,setEmail]=useState("");
    const [piece,setPiece]=useState("");
    const [current_status,setCurrentStatus]=useState("");
    const [city,setCity]=useState("");
    // const [rent,setRents]=useState("");
    const [guarantee,setGuarantee]=useState("");
    const [date_entrance,setDateEntrance]=useState("");
    const [house,setHouse]=useState("");
    const [date_emission,setEmission]=useState("");
    const [date_expiration,setExpiration]=useState("")

    useEffect(()=>{
        setName(person.name);
        setPre(person.prenom)
        setDate(person.birth);
        setLieu(person.lieu);
        setNationality(person.nationality);
        setSituation(person.situation);
        setSexe(person.sexe);
        setTel(person.tel);
        setProfession(person.profession);
        setEmail(person.email);
        setPiece(person.piece);
        setAddresse(person.address);
        setCurrentStatus(person.current_status);
        setCity(person.city);
        setHouse(homes.categorie);
        setGuarantee(person.guarantee);
        setDateEntrance(person.date_entrance);
        setEmission(person.date_emission);
        setExpiration(person.date_expiration)
    }, [homes])

    useEffect(() => {
        if (person && rentHomes.length > 0) {
            const matchedHome = rentHomes.find(home => home._id === person.home_id);
            if (matchedHome) {
                setHouse(matchedHome.categorie); // Assuming `categorie` is what you want to display
            }
        }
    }, [person, rentHomes]);

    const handleName = (event) =>{
        setName(event.target.value)
    }
    const handlePre = (event) =>{
        setPre(event.target.value)
    }
    const handleLieu = (event) =>{
        setLieu(event.target.value)
    }
    const handleDate = (event) =>{
        setDate(event.target.value)
    }
    const handleNationality = (event) =>{
        setNationality(event.target.value)
    }
    const handleSituation = (event) =>{
        setSituation(event.target.value)
    }
    const handleSexe = (event) =>{
        setSexe(event.target.value)
    }
    const handleTel = (event) =>{
        setTel(event.target.value)
    }
    const handleProfession = (event) =>{
        setProfession(event.target.value)
    }
    const handleAddresse = (event) =>{
        setAddresse(event.target.value)
    }
    const handleEmail = (event) =>{
        setEmail(event.target.value)
    }
    const handleCni = (event) =>{
        setPiece(event.target.value)
    }
    const handleCurrent = (event) =>{
        setCurrentStatus(event.target.value)
    }
    const handleHouse = (event) =>{
        setHouse(event.target.value)
    }
    const handleGuarantee = (event) =>{
        setGuarantee(event.target.value)
    }
    // const handleRents = (event) =>{
    //     setRents(event.target.value)
    // }
    const handleEntrance = (event) =>{
        setDateEntrance(event.target.value)
    }
    const handleExpiration = (event) =>{
        setExpiration(event.target.value)
    }
    const handleEmission = (event) =>{
        setEmission(event.target.value)
    }

    const notify = (e) => {
        toast('Profil mis à jour avec succès!!', {
          position: "top-right",
          autoClose: 3001,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      };

    const handleUpdate = (e) =>{
        const dataUpdate = {
          name,prenom,birth,lieu,nationality,address,current_status,date_emission,date_expiration,date_entrance,
          situation,tel,profession,email,piece,city,person_id:id,home_id:house
        }
        fetch(`https://mayedo.onrender.com/person/${id}`,{
              method:"PUT",
              headers :{'Content-Type':"application/json"},
              body: JSON.stringify(dataUpdate)
          }).then((res)=>res.json())
            .catch(err => {console.log(err)})
            navigate(`/detailPerson/${id}`);
            notify("") 
      };
  
    // useEffect(() => {
    //     fetch(`http://127.0.0.1:4000/persons/${id}`)
    //     .then((res)=>res.json())
    //     .then((people)=>{dispatch(getPeople(people))})
    //     .catch(e => { console.log(e)})
    //     }, [people])

    // console.log(house)

    const formatDate = (dateString) => {
        const dateParts = dateString.split('-');
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
         <Navbar/>
        <div className="containers">
        <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                       <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item " aria-current="page">Mise à jour</li>
                                    <li class="breadcrumb-item active" aria-current="page">Profil</li>
                                </ol>
                            </nav>
                       <div className='container__detail'>
            <div className="main__part__detail__people" >
                {/* <div className='btn__close__block'>
                    <Link to='/users'>
                        <button className='btn__back'><i class="fa-solid fa-arrow-left"></i></button>
                        </Link>
                </div> */}
                <div className="title__detail">
                        <h1>Mise à jour du profil</h1>
                </div>
                             <div className="main__part__detail__header">
                                {/* <div className="part__detail__person__image">
                                    <div className="part__detail__person__image__box" >
                                            <img src='27.PNG' alt="" />
                                    </div>
                                </div> */}

<div className="main__part__detail__header">
                                <div key={person.id} className="part__detail__person__detail">
                                <div className="detail--person">
                                    <p>Nom : {person.name}</p>
                                    <p>Prénom : {person.prenom}</p>
                                    <p>Né(e) : {formatDate(person.birth)}</p>
                                    <p>Nationnalité : {person.nationality}</p> 
                                    <p>Tel : {person.tel}</p>
                                    <p>Sexe : {person.sexe}</p>
                                    <p>Email: {person.email}</p>
                                    </div>
                                    <div className="detail--person">
                                    <p>Profession: {person.profession}</p>
                                    <p>Domicile : {person.address}</p>
                                    <p>Date d'entrée: {formatDate(person.date_entrance)}</p>
                                    <p>Nº CNI ou PAS : {person.piece}</p>
                                    <p>Date d'emission: {formatDate(person.date_emission)}</p>
                                    <p>Date d'expiration: {formatDate(person.date_expiration)}</p>
                                    {/* {person.home_id?.map((home,_id) => 
                                                <p>Loyer mensuel : {home.rent} FCFA</p>
                                                    )} */}
                                  
                                    </div>
                                    <div className="part__home__detail__container">
                                        <h6 className="ml__header">Detail maison </h6>
                                    <div className="part__home__detail__content">
                                {person.home_id?.map((home,_id) => <div className="home__content">
                                            <div className="home__content__left">
                                                <div className="home__image">
                                                {home.imageUrl.map((url, index) => (
                                                <img key={index} src={`http://localhost:4000${url}`} alt={`Home ${index}`} className="home-image" />
                                            ))}
                                                </div>

                                            </div>
                                            <div className="home__content__right">
                                                 <div>
                                                <p>Bien occupé : {home.categorie} </p>
                                                {/* <p>Nombres : {home.nombres}</p> */}
                                                <p>Addresse : {home.addressHome}</p>
                                                <p>Superficie : {home.superficie}</p>
                                                <p>Loyer mensuel : {home.rent} FCFA</p>
                                                <p>Caution : {home.guarantee} FCFA</p>
                                                 </div>
                                            </div>

                                        </div>                                        )}
                                </div>
                                    </div>
                                   
                                    {/* <div className="detail--person">
                                    <p>Maison : {person.house}</p>
                                    </div> */}
                                </div>

                            </div>
                            </div>
                            
                        </div> 
                    </div>  
                    <form className="row g-3">
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Noms</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleName} value={name} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Prénom(s)</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handlePre} value={prenom}/>
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date de naissance</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleDate} value={birth}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Lieu</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleLieu} value={lieu}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Nationalité</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleNationality} value={nationality} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Tel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleTel} value={tel}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">Profession</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleProfession} value={profession}/>
                                    </div>
                                    <div className="col-md-2">
                                    <label htmlFor="validationDefault02" className="form-label">Sexe</label>
                                            <select class="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                <option selected>Sélectionner</option>
                                                <option value="M">M</option>
                                                <option value="F">F</option>
                                                </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="validationDefault02" required onChange={handleEmail} value={email}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Domicile</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleAddresse} value={address}/>
                                    </div>
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Loyer mensuel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleRents} value={rent}/>
                                    </div> */}
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Caution</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleGuarantee} value={guarantee}/>
                                    </div> */}
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'entrée</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleEntrance} value={date_entrance}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">N° CNI ou PASSEPORT</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleCni} value={piece}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'emission</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleEmission} value={date_emission} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'expiration</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleExpiration} value={date_expiration} />
                                    </div>
                                    <div className="col-md-5">
                                            <label htmlFor="validationDefault02" className="form-label">Appartement(s) ou Villa(s)</label>
                                            <select class="form-select" aria-label="Default select example" onChange={handleHouse} value={house}>
                                                <option selected>Appartements ou Villas</option>
                                                {/* {rentHomes?.map((home_id,_id) => { return (  
                                                <option key={_id} value={home_id._id}>{home_id.categorie}</option>
                                                    )})} */}
                                                   {homes?.map((home_id, _id) => (
                                                    <option key={home_id.id} value={home_id._id}>{home_id.categorie}</option>
                                                    ))}
                                                     {/* {person.home_id?.map((home_id,_id)=> (
                                                    <option key={home_id._id} value={home_id._id}>{home_id.categorie}</option>
                                                    ))} */}
                                                     {/* {person.home_id?.map((home,_id) => 
                                     <option key={_id} value={home_id._id}>{home.rent} FCFA</option> 
                                     )} */}
                                                </select>
                                                
                                    </div>
                                    <div className="btn--block--update">
                                            <Link to="/detailPerson"><button className="btn__">Annuler</button></Link>
                                            <button className="btn__" onClick={handleUpdate}>Enregistrer</button>
                                    </div>
                                    </form>                        
                           </div>
                       </div>
                   </div>
  
    </div>
    <Footer/>
   </div>
    )
}