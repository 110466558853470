import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";




export default function Notif(){
    return (
        <div>
        <div className="containers">
        <Navbar />
        <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                       <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Notifications</li>
                                </ol>
                            </nav>
                                <h1>Notifications</h1>
                                <table class="table">
                                <thead>
                                    <tr>
                                    {/* <th scope="col">id</th> */}
                                    <th scope="col">id</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Addresse</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Montants</th>
                                    <th scope="col">Statu(s)</th>
                                    <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                    {/* <th scope="row">1</th> */}
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>Otto</td>
                                    <td><select class="form-select" aria-label="Default select example">
                                        <option selected>Statu(s)</option>
                                        <option value="1">Non livrer</option>
                                        <option value="2">Livrer</option>
                                        <option value="3">Annuler</option>
                                        </select></td>
                                        <td> <button className="check__btn">Validé</button></td>
                                    </tr>
                                    <tr>
                                    {/* <th scope="row">2</th> */}
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>Otto</td>
                                    <td>@fat</td>
                                    <td>Otto</td>
                                    <td><select class="form-select" aria-label="Default select example">
                                        <option selected>Statu(s)</option>
                                        <option value="1">Non livrer</option>
                                        <option value="2">Livrer</option>
                                        <option value="3">Annuler</option>
                                        </select></td>
                                        <td> <button className="check__btn">Validé</button></td>
                                    </tr>
                                    <tr>
                                    {/* <th scope="row">3</th> */}
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>Otto</td>
                                    <td>@fat</td>
                                    <td>Otto</td>
                                    <td><select class="form-select" aria-label="Default select example">
                                        <option selected>Statu(s)</option>
                                        <option value="1">Non livrer</option>
                                        <option value="2">Livrer</option>
                                        <option value="3">Annuler</option>
                                        </select></td>
                                    <td> <button className="check__btn">Validé</button></td>
                                    </tr>
                                </tbody>
                                </table>
                            <div>
                            </div>
                           </div>
                       </div> 
                   </div>
    </div>
   </div>
    )
}