import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllHomes, addPerson } from "../Redux/actions";

export default function NewUser({ setNewPeople, newPeople }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rentHomes = useSelector((state) => state.peopleReducer.rentHomes);

    const UserDisplay = () =>{
        setNewPeople(false)
    }

    const [formData, setFormData] = useState({
        name: "",
        prenom: "",
        birth: "",
        lieu: "",
        nationality: "",
        situation: "",
        sexe: "",
        tel: "",
        profession: "",
        address: "",
        email: "",
        house: "",
        piece: "",
        current_status: "",
        city: "",
        date_entrance: "",
        date_emission: "",
        date_expiration: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAdd = (e) => {
        e.preventDefault();
        fetch('https://mayedo.onrender.com/persons', {
            method: "POST",
            headers: { 'Accept': "application/json", 'Content-Type': "application/json" },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((dataPerson) => {
                dispatch(addPerson(dataPerson));
                navigate('/');
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        fetch('https://mayedo.onrender.com/homes')
            .then((res) => res.json())
            .then((data) => dispatch(getAllHomes(data)))
            .catch(e => console.log(e));
    }, [dispatch]);
  
    return (
            <div>
                <div className={newPeople ? "container__user__modal open__user__modal" : "container__user__modal"}>
                              <div className="container__payment__header">
                              <h3 className="gle">#Ajouter un nouveau locataire</h3>
                              <i className="fa-solid fa-xmark" onClick={UserDisplay}></i>
                              </div>
                                <form className="row g-3">
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Nom</label>
                                        <input type="text" className="form-control" id="validationDefault02" required  onChange={handleInputChange} value={formData.name} defaultValue="Search..."/>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Prénom(s)</label>
                                        <input type="text" className="form-control" id="validationDefault02" required  onChange={handleInputChange} value={formData.prenom} defaultValue="Search..."/>
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date de naissance</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.birth}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Lieu</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.lieu}/>
                                    </div>
                                    <div className="col-md-2">
                                    <label htmlFor="validationDefault02" className="form-label">Sexe</label>
                                            <select class="form-select" aria-label="Default select example" required onChange={handleInputChange} value={formData.sexe} >
                                                <option selected>Sélectionner</option>
                                                <option value="M">M</option>
                                                <option value="F">F</option>
                                                </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Nationalité</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.nationality} />
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Ville</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.city}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Tel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.tel}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Profession</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.profession}/>
                                    </div>
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Sexe</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleSexe} value={sexe}/>
                                    </div> */}
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.email}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">Domicile</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.address}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">N° CNI ou PASSEPORT</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.piece}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'emission</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.date_emission} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'expiration</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.date_expiration} />
                                    </div>
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label"> Cautions</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleGuarantee} value={guarantee}/>
                                    </div> */}
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label"> Loyer Mensuel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleRents} value={rent}/>
                                    </div> */}
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'entrée</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleInputChange} value={formData.date_entrance} />
                                    </div>
                                    
                                    {/* <div className="col-md-3">
                                        <label htmlFor="formFile" className="form-label">Default file input example</label>
                                        <input className="form-control" type="file" id="formFile"/>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <select class="form-select" aria-label="Default select example" onChange={handleSituation} value={situation}>
                                                <option selected>Situation matrimoniale</option>
                                                <option value="1">Célibataire</option>
                                                <option value="2">Marié(e)</option>
                                                <option value="3">Veuve</option>
                                                <option value="4">Concubinage</option>
                                                </select>
                                        </div> */}
                                    {/* <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">Appartement(s) ou villa(s)</label>
                                            <select class="form-select" aria-label="Default select example" onClick={handleHouse} value={house} required >
                                            <option selected>Sélectionner</option>
                                                {rentHomes?.map((home_id,_id) => { return (  
                                                <option key={_id} value={home_id._id}>{home_id.categorie}</option>)})}
                                            </select>
                                    </div> */}
                                    <div className="col-md-2">
                        <label htmlFor="house" className="form-label">Villa(s) ou Appartement(s)</label>
                        <select className="form-select" id="house" name="house" onChange={handleInputChange} value={formData.house} required>
                            <option value="">Sélectionner</option>
                            {rentHomes && rentHomes.map((rentHome, _id) => (
                                <option key={_id} value={rentHome._id}>{rentHome.categorie}</option>
                            ))}
                        </select>
                    </div>
                                    <div className="btn--block--update">
                                            <button className="btn__" onClick={UserDisplay}>Annuler</button>
                                            <button className="btn__" onClick={handleAdd}>Enregistrer</button>
                                    </div>
                                    </form>
             </div>
  
            </div>
    )
}