import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Inscription() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [username, setUserName] = useState('');
    const [prenom, setPrenom] = useState('');
    const [address, setAddress] = useState('');
    const [numero, setNumero] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [uploads, setUpload] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        setUpload(e.target.files); // Store multiple file objects
    };

    const RegisterHandler = (e) => {
        e.preventDefault();

        if (password !== password2) {
            toast.error("Les mots de passe ne correspondent pas");
            return;
        }

        if (!uploads || uploads.length === 0) {
            toast.error("Veuillez télécharger une image");
            return;
        }

        setLoading(true);

        // Create FormData object
        const formData = new FormData();
        formData.append('name', name);
        formData.append('prenom', prenom);
        formData.append('numero', numero);
        formData.append('address', address);
        formData.append('username', username);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('password2', password2);
        formData.append('image', uploads[0]); // append the first selected file

        fetch('https://mayedo.onrender.com/register', {
            method: "POST",
            body: formData // Send FormData instead of JSON
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Erreur d'inscription");
            }
            return res.json();
        })
        .then((userData) => {
            setLoading(false);
            localStorage.clear();
            localStorage.setItem("user", JSON.stringify(userData));
            toast.success("Inscription réussie ! ");
            toast.success("Veuillez vous connecter svp ! ");
            navigate('/');
        })
        .catch(err => {
            setLoading(false);
            toast.error(err.message);
        });
    };

    return (
        <div>
            <div className="assisst__header">
                <div className="assisst__header__content">
                    <p><i className="fa-solid fa-phone"></i> Service assistance</p>
                    <p>+225 07 77 88 00 82</p>
                    <p>doumbia77fode@gmail.com</p>
                    <Link to="/" className="link__sidebar"><p>Connexion</p></Link>
                </div>
            </div>

            <div className="container__register">
                <div className='container__register__content'>
                    <div className='container__register__content__header'>
                        <div className='container__register__content__header__logo'>
                            <img src={`${process.env.PUBLIC_URL}/lo.png`} alt='' />
                        </div>
                    </div>
                    <h4 className="register__title">MY DASH</h4>
                    <div className='register__content'>
                        <div className='register__content__form'>
                            <h4 className="register__title">Bienvenue sur notre plateforme de gestion locative</h4>
                            <form method="post" encType="multipart/form-data">
                                <div className="input-group mb-3">
                                    <input type="file" className="form-control" id="inputGroupFile02" onChange={handleFileChange} name="userUploads" />
                                    <label className="input-group-text" htmlFor="inputGroupFile02">Télécharger</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="Username" value={username} onChange={(e) => setUserName(e.target.value)} />
                                    <label htmlFor="floatingInput">@username</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="Nom" value={name} onChange={(e) => setName(e.target.value)} />
                                    <label htmlFor="floatingInput">Nom</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="Prénom" value={prenom} onChange={(e) => setPrenom(e.target.value)} />
                                    <label htmlFor="floatingInput">Prénom</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="Adresse" value={address} onChange={(e) => setAddress(e.target.value)} />
                                    <label htmlFor="floatingInput">Adresse</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="Numéro" value={numero} onChange={(e) => setNumero(e.target.value)} />
                                    <label htmlFor="floatingInput">Numéro</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <label htmlFor="floatingInput">Email</label>
                                </div>
                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <label htmlFor="floatingPassword">Mot de passe</label>
                                </div>
                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                                    <label htmlFor="floatingPassword">Confirmation Mot de passe</label>
                                </div>
                                <Link to="/"><button className='btn__register'>Annuler</button></Link>
                                <button className='btn__register' onClick={RegisterHandler} disabled={loading}>
                                    {loading ? "Inscription..." : "Inscription"}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}