import { Link, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { getAllPerson, getRent } from "../Redux/actions";
import { Blocks } from "react-loader-spinner";
import NewUser from "./NewUser";
import Footer from './Footer';

export default function Statistiques() {
    const id = useParams().id;
    const persons = useSelector(state => state.peopleReducer.persons);
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [newPeople, setNewPeople] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        fetch('https://mayedo.onrender.com/persons')
            .then((res) => res.json())
            .then((persons) => { dispatch(getAllPerson(persons)) })
            .catch(e => { console.log(e) });
        setTimeout(() => { setLoading(false) }, 1500);
    }, [dispatch]);

    useEffect(() => {
        fetch(`https://mayedo.onrender.com/rents/${id}`)
            .then((res) => res.json())
            .then((rental) => { dispatch(getRent(rental)) })
            .catch(e => { console.log(e) });
        setTimeout(() => { setLoading(false) }, 1000);
    }, [dispatch, id]);

    const filterPersons = useMemo(() => {
        const formattedMonth = search ? new Date(search).toISOString().slice(0, 7) : "";

        return persons
            .filter(person => 
                // Filter by search query (name or contact)
                person.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                person.prenom.toLowerCase().includes(searchQuery.toLowerCase()) ||
                person.tel.includes(searchQuery)
            )
            .map((person) => {
                // Filter rentals based on the selected month and status "Payé"
                const filteredRentals = person.rentals.filter((item) => 
                    item.month.startsWith(formattedMonth) && item.status === "Payé"
                );

                return { ...person, rentals: filteredRentals };
            });
    }, [persons, search, searchQuery]);

    return (
        <div>
             <Navbar/>
            <div className="containers">
                <div className="dashboard">
                    <div className="left">
                        <Sidebar />
                    </div>
                    <div className="right">
                        {/* <Navbar /> */}
                        <div className="firstly">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Statistiques Locataires</li>
                                </ol>
                            </nav>
                            <div className="firstly__header">
                                <h1><i className="fa-solid fa-gauge-high"></i> Statistiques </h1>
                            </div>
                            <NewUser newPeople={newPeople} setNewPeople={setNewPeople} />
                            <div className="container__statistiques">
                               <div className="container__statistiques__filter__box">
                               <div className="filter--container--statistiques--left">
                                    <div className="box--filter">
                                        <div className="col-md-3">
                                            <label htmlFor="validationDefault02" className="form-label">Date de paiement</label>
                                            <input type="month" className="form-control" id="validationDefault02" required value={search} onChange={handleSearch} />
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="filter--container--statistiques--right">
                                <div className="col-md-12">
                                            {/* <label htmlFor="validationDefault02" className="form-label">Date de paiement</label> */}
                                            <input type="text" placeholder="Rechercher un locataire...." required value={searchQuery} onChange={handleSearchQuery}/>
                                                </div>
                                </div>
                               </div>
                                {loading ? (
                                    <Blocks visible={true} height="80" width="100%" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
                                ) : (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="coler">Nom & Prénom(s)</th>
                                                <th className="coler">Contacts</th>
                                                <th className="coler">Bien</th>
                                                <th className="coler">Mois</th>
                                                <th className="coler">Statut</th>
                                                <th className="coler">Montants</th>
                                                <th className="coler">Details</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider">
                                            {filterPersons && filterPersons.map((person) => person.rentals.length > 0 && (
                                                <tr key={person._id}>
                                                    <td className="coles">{person.name} {person.prenom}</td>
                                                    <td className="coles">{person.tel}</td>
                                                    {person.home_id?.map((home, _id) => <td className="coles" key={_id}>{home.categorie}</td>)}
                                                    {person.rentals.map((rental, _id) => (
                                                        <td className="coles" key={rental._id}>
                                                            {new Date(rental.month).toLocaleString('default', { month: 'long' })}
                                                        </td>
                                                    )).at(-1)}
                                                    {person.rentals.map((rental, _id) => (
                                                        <td className="coles" key={rental._id}>{rental.status}</td>
                                                    )).slice(-1)}
                                                    {person.home_id?.map((home, _id) => <td className="coles" key={_id}>{home.rent} FCFA</td>)}
                                                    <td className="coles"> <Link to={`/detailUser/${person._id}`}><button className="details__btn">Details</button></Link></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}