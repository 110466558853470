import { useState } from "react";
import { useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { getPerson, getRent } from "../Redux/actions";
import { useReactToPrint } from "react-to-print";
import { TailSpin } from "react-loader-spinner";
import Footer from "./Footer";
import Navbar from "./Navbar";


export default function PaiementDetail(){

        var id = useParams().id;
    const person = useSelector(state=>state.peopleReducer.person);
    const rental = useSelector(state=>state.peopleReducer.rental);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(`https://mayedo.onrender.com/persons/${id}`)
        .then((res)=>res.json())
        .then((person)=>{dispatch(getPerson(person))})
        .catch(e => { console.log(e)})
        setTimeout(()=>{setLoading(false)},1000);   
        }, [person])

        useEffect(() => {
            fetch(`https://mayedo.onrender.com/rents/${id}`)
            .then((res)=>res.json())
            .then((rental)=>{dispatch(getRent(rental))})
            .catch(e => { console.log(e)})
            setTimeout(()=>{setLoading(false)},1000);   
            }, [rental])

      const componentRef =useRef();
      const handlePrint =useReactToPrint({
        content:()=> componentRef.current,
        documentTitle:person.name +' '+person.prenom,
        // onAfterPrint:()=> alert('Print successful')
      })

    //   const dateString = "2024-04";
    //   const dateParts = dateString.split("-");
    //   const year = parseInt(dateParts[0]);
    //   const month = parseInt(dateParts[1]);
    
    //   // Créer un nouvel objet Date avec l'année et le mois
    //   const date = new Date(year, month - 1);
    //         // console.log(person.home_id)

    const formatDate = (dateString) => {
        if (!dateString) return ''; // Return an empty string or a default value if the dateString is undefined
        const dateParts = dateString.split('-');
        if (dateParts.length !== 3) return ''; // Additional check to ensure the date string is in the correct format
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            <Navbar/>
        <div className="containers">
            <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                   {loading ? (
                                 <TailSpin
                                 height="80"
                                 width="100%"
                                 display='flex'
                                 align-items='center'
                                 color="#4fa94d"
                                 ariaLabel="tail-spin-loading"
                                 radius="1"
                                 wrapperStyle={{}}
                                 wrapperClass=""
                                 visible={true}
                               />
                                    ) :(     <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item " aria-current="page"><Link to='/persons'>Locataires</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page"><Link to={`/detailPerson/${id}`}>Details du profil</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Details des paiements</li>
                                </ol>
                            </nav>
                       <div className='container__detail__paiements'>
            <div className="main__part__detail__people" >
                <div className='nav--pdf'>
                    {/* <Link to='/users'><button className='btn__back'><i class="fa-solid fa-arrow-left"></i></button></Link> */}
                    <button className="button__pdf" onClick={handlePrint}><i class="fa-solid fa-print"></i> Imprimer</button>
                </div>
                {/* <div className="title__detail">
                        <h1><i>Profil</i></h1>
                </div> */}
                <div ref={componentRef} style={{width:'100%',height:window.innerHeight}}>
                             <div className="main__part__detail__header">
                                <div key={id} className="part__details">
                                <div className="detail--person">
                                    <div className="logo__nv">
                                    <img src={`${process.env.PUBLIC_URL}/27.png`} alt=''/>
                                    </div>
                                    </div>
                                <div className="detail--person">
                                    <p>Nom : {person.name}</p>
                                    <p>Prénom : {person.prenom}</p>
                                    <p>Né(e) : {formatDate(person.birth)}</p>
                                    <p>Nationnalité : {person.nationality}</p>
                                    <p>Email: {person.email}</p> 
                                    </div>

                                    <div className="detail--person">
                                    <p>Tel : {person.tel}</p>
                                    <p>Profession: {person.profession}</p>
                                    <p>Domicile : {person.address}</p>
                                    <p>Nº CNI/PAS : {person.piece}</p>
                                    <p>Date d'entrée: {formatDate(person.date_entrance)}</p>
                                    </div>

                                    {person.home_id?.map((home,_id) => <div className="detail--person">
                                            <div>
                                                <p>Bien occupé : {home.categorie} </p>
                                                <p>Addresse : {home.address}</p>
                                                <p>Loyer mensuel : {home.rent} FCFA</p> 
                                            </div>

                                        </div>
                                        )}
                                </div>

                            </div>
                       
                    
                        <div className="paiement__block">
                                        <h3><i>Details des paiements</i></h3>
                                        </div>
                                <table class="table">
                                <thead>
                                    <tr>
                                    <th className="coler">Heures de paiements</th>
                                    <th className="coler">Date de paiement</th>
                                    <th className="coler">Mois</th>
                                    <th className="coler">Montants</th>
                                    <th className="coler">Statut</th>
                                    <th className="coler">Mode</th>
                                    </tr>
                                </thead>
                                <tbody  class="table-group-divider">
                                    {person.rentals?.map((rental,_id) => { return ( <tr key={rental._id}>
                                        <td className="coles">{rental.time}</td>
                                    <td className="coles"> {new Date(rental.date_of_payment).toLocaleString()}</td>
                                    <td className="coles">{new Date(rental.month).toLocaleString('default', { month: 'long' })}</td>
                                    {person.home_id?.map((home,_id) => 
                                     <td className="coles">{home.rent} FCFA</td> 
                                     )}
                                    <td className="coles">{rental.status}</td>
                                    <td className="coles">{rental.mode}</td>
                                    </tr>)}
                                    )}
                                </tbody>
                                </table>
                                </div>
                               </div>                        
                           </div>
                           </div> 
                                    )}
                       </div>
                   </div>
  
    </div>
    <Footer/>
   </div>
    )
}