import { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate, useParams  } from "react-router-dom";
import { useDispatch,useSelector} from "react-redux";
import { addPerson,getAllHomes, getAllUser, getPerson, getUser } from "../Redux/actions";
import { useMgectxt } from "./DataProvider";
import Footer from './Footer';
import { useUserContext } from '../contexts/UserContext';




export default function Ajout(){
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    var id = useParams().id;
    const { user } = useUserContext(); // Access context
    const user_id = useSelector(state=>state.peopleReducer.user_id);
    const [rentHomes, setRentHomes] = useState([]);
    // const [user_id,setUserId]=useState([])
//     const {data,handleData}=useMgectxt()
//   const {id} = location.state || {};
//     console.log('A',id)

//      useEffect(()=>{
//     handleData(user_id)
//   },[user_id])

//   const handleId = (id)=>{
//     setUserId(id)
//   }
//  console.log('Ajout:',handleId)

    const [name,setFullName]=useState("");
    const [prenom,setPrenom]=useState("");
    const [birth,setDate]=useState("");
    const [lieu,setLieu]=useState("");
    const [nationality,setNationality]=useState("");
    const [situation,setSituation]=useState("");
    const [sexe,setSexe]=useState("");
    const [tel,setTel]=useState("");
    const [profession,setProfession]=useState("");
    const [address,setAddresse]=useState("");
    const [email,setEmail]=useState("");
    const [house,setHouses]=useState();
    const [piece,setPiece]=useState("");
    const [current_status,setCurrentStatus]=useState("");
    const [city,setCity]=useState("");
    const [date_entrance,setDateEntrance]=useState("");
    const [date_emission,setEmission]=useState("");
    const [date_expiration,setExpiration]=useState("")
    const [guarantee,setGuarantee]=useState("")


    const handleFull = (event) =>{
        setFullName(event.target.value)
    }
    const handlePrenom = (event) =>{
        setPrenom(event.target.value)
    }
    const handleLieu = (event) =>{
        setLieu(event.target.value)
    }
    const handleDate = (event) =>{
        setDate(event.target.value)
    }
    const handleEntrance = (event) =>{
        setDateEntrance(event.target.value)
    }
    const handleNationality = (event) =>{
        setNationality(event.target.value)
    }
    const handleSituation = (event) =>{
        setSituation(event.target.value)
    }
    const handleSexe = (event) =>{
        setSexe(event.target.value)
    }
    const handlePiece = (event) =>{
        setPiece(event.target.value)
    }
    const handleTel = (event) =>{
        setTel(event.target.value)
    }
    const handleProfession = (event) =>{
        setProfession(event.target.value)
    }
    const handleAddresse = (event) =>{
        setAddresse(event.target.value)
    }
    const handleEmail = (event) =>{
        setEmail(event.target.value)
    }
    const handleHouses = (event) =>{
        setHouses(event.target.value)
    }
    const handleGuarantee = (event) =>{
        setGuarantee(event.target.value)
    }
    // const handleRents = (event) =>{
    //     setRents(event.target.value)
    // }
    const handleCurrent = (event) =>{
        setCurrentStatus(event.target.value)
    }
    const handleCity = (event) =>{
        setCity(event.target.value)
    }
    const handleExpiration = (event) =>{
        setExpiration(event.target.value)
    }
    const handleEmission = (event) =>{
        setEmission(event.target.value)
    }
    //    const AddNewPerson = ()=>{
    //     const Add = {
    //             name,
    //             lieu,nationality,city,tel
    //             ,profession,sexe,email,address

    //     }
    //     dispatch(addPerson(Add));
    //     navigate('/users');
    //     console.log(AddNewPerson)
    //    }
  
    const handleAdd = (e) =>{
        e.preventDefault();
        const dataForm = {
            name,
            prenom,
            birth,
            lieu,
            nationality,
            address,
            current_status,
            date_expiration,
            date_emission,
            situation,
            sexe,
            tel,
            profession,
            email,
            piece,
            date_entrance,
            home_id: house,
            user_id: id
         };
         fetch('https://mayedo.onrender.com/persons', {
            method: "POST",
            headers: { 'Accept': "application/json", 'Content-Type': "application/json" },
            body: JSON.stringify(dataForm),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Failed to add person');
                }
                return res.json();
            })
            .then((dataPerson) => {
                dispatch(addPerson(dataPerson));
                  toast.success("Locataire ajouté avec succès!"); 
                navigate('/persons');
            })
            .catch(err => toast.error(`Error: ${err.message}`));
    };


      
    useEffect(() => {
        fetch(`https://mayedo.onrender.com/homes?user_id=${user.id}`)
            .then((res) => res.json())
            .then((data) => {
                setRentHomes(data); // Update the rentHomes state
            })
            .catch((e) => {
                console.log(e);
                setRentHomes([]); // Set to empty array in case of error
            });
    }, []);

        useEffect(() => {
            fetch(`https://mayedo.onrender.com/users/${id}`)
            .then((res)=>res.json())
            .then((user_id)=>{dispatch(getAllUser(user_id))})
            .catch(e => { console.log(e)})
            }, [user_id])

            console.log('sdfg',user_id)

  
    return (
        <div>
            <Navbar/>
             <div className="containers">
             <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                   {/* <Navbar/> */}
                            <div className="firstly">
                                <h1 className="firstly__title"><i className="fa-solid fa-users"></i> Ajouter un nouveau </h1>
                                <form className="row g-3">
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Nom</label>
                                        <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Prénom(s)</label>
                                        <input type="text" className="form-control" id="validationDefault02" required  onChange={handlePrenom} value={prenom|| ""} defaultValue="Search..."/>
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date de naissance</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleDate} value={birth} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Lieu</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleLieu} value={lieu}/>
                                    </div>
                                    <div className="col-md-2">
                                    <label htmlFor="validationDefault02" className="form-label">Sexe</label>
                                            <select class="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                <option selected>Sélectionner</option>
                                                <option value="M">M</option>
                                                <option value="F">F</option>
                                                </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Nationalité</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleNationality} value={nationality} />
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Ville</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleCity} value={city}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Tel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleTel} value={tel}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Profession</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleProfession} value={profession}/>
                                    </div>
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Sexe</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleSexe} value={sexe}/>
                                    </div> */}
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="validationDefault02" required onChange={handleEmail} value={email}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Domicile</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleAddresse} value={address}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">N° CNI ou PASSEPORT</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handlePiece} value={piece}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'emission</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleEmission} value={date_emission} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'expiration</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleExpiration} value={date_expiration} />
                                    </div>
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label"> Cautions</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleGuarantee} value={guarantee}/>
                                    </div> */}
                                    {/* <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label"> Loyer Mensuel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleRents} value={rent}/>
                                    </div> */}
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'entrée</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleEntrance} value={date_entrance} />
                                    </div>
                                
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">Biens</label>
                                        <select className="form-select" aria-label="Default select example" onChange={handleHouses} value={house} required>
                                            <option selected>Sélectionner</option>
                                            {rentHomes.length > 0 && rentHomes.map((rentHome) => (
                                                <option key={rentHome._id} value={rentHome._id}>{rentHome.categorie}</option>
                                            ))}
                                        </select>
                                    </div>

                                    

                                    <div className="btn--block--update">
                                            <Link to='/users'><button className="btn__">Annuler</button></Link>
                                            <button className="btn__" onClick={handleAdd}>Enregistrer</button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                            
                           

                            
                        </div>
       
         </div>
         <Footer/>
        </div>
    )
}